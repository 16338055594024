import { Component, Vue, Watch } from 'vue-property-decorator';
import { DateTime } from 'luxon';
import { AxiosError, AxiosResponse } from 'axios';

import { TreeViewColumn } from '@/components/tree-view/TreeView';

import ErrorHandler from '@/support/ErrorHandler';

import { ReportType } from '@/models/ReportType';
import { Organization } from '@/models/Organization';
import { UserType } from '@/models/User';

import { ReportTypeObject } from '@/views/ReportStats/ReportStats';
import { Rpc } from '@/models/Rpc';

@Component<VoorstelPrt>({})
export default class VoorstelPrt extends Vue {
  public $pageTitle = 'Voorstel PRT';

  // organizations
  protected organizations: Organization[] = [];

  protected selectedOrganizations: string[] = [];

  // report types
  protected reportTypes: ReportTypeObject[] = [];

  protected selectedReportTypes: string[] = [];

  // user types
  protected userTypes: UserType[] = [
    {
      key: 'expert',
      name: 'Opnemer',
    },
    {
      key: 'second_expert',
      name: 'Deskundige (indien anders dan opnemer)',
    },
    {
      key: 'tc',
      name: 'Technisch Coordinator',
    },
  ];

  protected selectedUserType = 'expert';

  // breakdown
  protected breakdowns: {[key: string]:string }[] = [
    {
      name: 'Type, Dossier',
      key: 'type_report',
    },
    {
      name: 'Type, Actiehouder, Dossier',
      key: 'type_employee_report',
    },
    {
      name: 'Actiehouder, Type, Dossier',
      key: 'employee_type_report',
    },
  ];

  protected selectedBreakdown = 'type_report';

  protected columns: TreeViewColumn[] = [
    {
      key: 'first',
      name: '',
      visible: true,
    },
    {
      key: 'delivered_reports',
      name: '# Opgeleverd',
      class: 'text-xs-right',
      visible: true,
    },
    {
      key: 'damages_count',
      name: '# Opgenomen schades (gem.)',
      class: 'text-xs-right',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(number);
      },
    },
    {
      key: 'damages_causaal_count',
      name: 'Causale schades (gem.)',
      class: 'text-xs-right col-performance',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'damages_not_causaal_count',
      name: 'Niet Causale schades (gem.)',
      class: 'text-xs-right col-performance',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'payout_total_damage',
      name: '€ per schade (gem.)',
      class: 'text-xs-right col-performance',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'payout_total_report',
      name: '€ per rapport (gem.)',
      class: 'text-xs-right col-performance',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'first_time_right',
      name: '% FTR (gem.)',
      class: 'text-xs-right col-ftr',
      postfix: '%',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'doorlooptijd',
      name: 'Doorlooptijd in dagen (gem.)',
      class: 'text-xs-right col-doorlooptijd',
      postfix: 'd.',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 1,
          maximumFractionDigits: 1,
        }).format(number);
      },
    },
    {
      key: 'doorlooptijd_percentage',
      name: '% Doorlooptijd binnen norm (gem.)',
      class: 'text-xs-right col-doorlooptijd',
      postfix: '%',
      visible: true,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
    {
      key: 'costs',
      name: 'Kosten per dossier (gem.)',
      class: 'text-xs-right col-costs',
      visible: this.$store.state.Auth.is_rockefeller,
      transform(number : number) {
        return new Intl.NumberFormat('nl-NL', {
          style: 'currency',
          currency: 'EUR',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(number);
      },
    },
  ];

  // dates
  protected isEditingFrom = false;

  protected isEditingTo = false;

  protected fromDate = '';

  protected toDate = '';

  // date filter
  protected dateFilterType = '';

  protected isShowingDateFilterDialog = false;

  // loaders
  protected isLoading = true;

  protected items: RealtimeManagementStruct[] = [];

  public mounted() {
    this.initialize();
  }

  protected initialize() {
    const lastDayOfMonth = DateTime.local().daysInMonth;
    this.fromDate = `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.toDate = `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;

    this.emitBreadcrumb();
    this.getOrganizations();
    this.getReportTypes();
    this.applyFilters();
  }

  protected getOrganizations() {
    new Organization()
      .all()
      .then((organizations: Organization[]) => {
        this.organizations = organizations;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected getReportTypes() {
    new ReportType()
      .all()
      .then((response: ReportTypeObject[]) => {
        this.reportTypes = response;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected applyFilters() {
    this.isLoading = true;
    const payload = {
      signature: 'management-reports:delivered',
      body: {
        from: this.fromDate,
        to: this.toDate,
        types: this.selectedReportTypes,
        organizations: this.selectedOrganizations,
        actor: this.selectedUserType,
        breakdown: this.selectedBreakdown,
      },
    };

    new Rpc()
      .rpcPost(payload, false)
      .then((response: AxiosResponse) => {
        this.items = response.data;
        this.isLoading = false;
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        this.isLoading = false;
      });
  }

  protected applyDateFilters(from: string, to: string) {
    this.fromDate = from;
    this.toDate = to;
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Rapportage / Opgeleverd Matrix' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

interface RealtimeManagementStruct {
  id?: string;
  active: boolean;
  name: string;
  data: RealtimeManagementData;
  children: RealtimeManagementStruct[];
}

interface RealtimeManagementData {
  planned_reports: string;
  delivered_reports: string;
  first_time_right: string;
  doorlooptijd: string;
  doorlooptijd_percentage: string;
  costs: string;
}
