var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs12: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Voorstel PRT Dashboard")
            ])
          ]),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced fill-height" },
              [
                _vm.isLoading
                  ? _c("LoaderCard", {
                      attrs: { flat: "", type: "spinner--center" }
                    })
                  : _vm._e(),
                !_vm.isLoading
                  ? [
                      _c(
                        "v-layout",
                        { attrs: { wrap: "", "py-2": "", "align-end": "" } },
                        [
                          _c(
                            "v-flex",
                            {
                              staticClass: "text-xs-right",
                              attrs: { sm12: "" }
                            },
                            [
                              _c(
                                "MiButton",
                                {
                                  attrs: {
                                    color: "primary",
                                    small: "true",
                                    outline: "true"
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.applyFilters()
                                    }
                                  }
                                },
                                [_vm._v(" Pas filters toe ")]
                              )
                            ],
                            1
                          ),
                          _vm.$store.state.isServiceOrganization
                            ? _c(
                                "v-flex",
                                { attrs: { xs12: "", sm2: "" } },
                                [
                                  _c("v-autocomplete", {
                                    staticClass: "with-border",
                                    attrs: {
                                      items: _vm.organizations,
                                      "item-text": "name",
                                      "item-value": "id",
                                      "hide-details": "",
                                      label: "Bureaus",
                                      multiple: "",
                                      clearable: ""
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "selection",
                                          fn: function(ref) {
                                            var item = ref.item
                                            var index = ref.index
                                            return [
                                              index === 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      "Bureaus (" +
                                                        _vm._s(
                                                          _vm
                                                            .selectedOrganizations
                                                            .length
                                                        ) +
                                                        ")"
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      1238192415
                                    ),
                                    model: {
                                      value: _vm.selectedOrganizations,
                                      callback: function($$v) {
                                        _vm.selectedOrganizations = $$v
                                      },
                                      expression: "selectedOrganizations"
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm2: "" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "with-border",
                                attrs: {
                                  items: _vm.reportTypes,
                                  "item-value": "uuid",
                                  "item-text": "name",
                                  "hide-details": "",
                                  label: "Rapporttypes",
                                  clearable: "",
                                  multiple: ""
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "selection",
                                      fn: function(ref) {
                                        var item = ref.item
                                        var index = ref.index
                                        return [
                                          index === 0
                                            ? _c("span", [
                                                _vm._v(
                                                  "Types (" +
                                                    _vm._s(
                                                      _vm.selectedReportTypes
                                                        .length
                                                    ) +
                                                    ")"
                                                )
                                              ])
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  297438334
                                ),
                                model: {
                                  value: _vm.selectedReportTypes,
                                  callback: function($$v) {
                                    _vm.selectedReportTypes = $$v
                                  },
                                  expression: "selectedReportTypes"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm2: "" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "with-border",
                                attrs: {
                                  items: _vm.breakdowns,
                                  "item-value": "key",
                                  "item-text": "name",
                                  "hide-details": "",
                                  label: "Breakdown"
                                },
                                model: {
                                  value: _vm.selectedBreakdown,
                                  callback: function($$v) {
                                    _vm.selectedBreakdown = $$v
                                  },
                                  expression: "selectedBreakdown"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs12: "", sm2: "" } },
                            [
                              _c("v-autocomplete", {
                                staticClass: "with-border",
                                attrs: {
                                  items: _vm.userTypes,
                                  "item-value": "key",
                                  "item-text": "name",
                                  "hide-details": "",
                                  label: "Actiehouder"
                                },
                                model: {
                                  value: _vm.selectedUserType,
                                  callback: function($$v) {
                                    _vm.selectedUserType = $$v
                                  },
                                  expression: "selectedUserType"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "overflow-auto" },
                        [
                          _c("TreeView", {
                            attrs: {
                              first: true,
                              items: _vm.items,
                              columns: _vm.columns
                            }
                          })
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }